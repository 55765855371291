<template>
  <div>
    <div class="navigation-bottom ">
      <div class="custom-tour left-16" @click="changeTourStatus" v-show="!customerHomeTour"><span>برای بازدید از ملک خود اینجا کلیک کنید</span></div>
      <router-link :to="{name : 'AddRequestStep1'  }" class="add" >
        <svg @click="changeTourStatus" xmlns="http://www.w3.org/2000/svg" width="19.97" height="19" viewBox="0 0 19.97 19">
          <g id="Group_3" data-name="Group 3" transform="translate(-698 -3411)">
            <path id="ic_location_city_24px" d="M15,11V5L12,2,9,5V7l.147,14H21V11Zm-2,8H11V17h2Zm0-4H11V13h2Zm0-4H11V9h2Zm0-4H11V5h2Zm6,12H17V17h2Zm0-4H17V13h2Z" transform="translate(689 3409)" fill="#fff"/>
            <path id="ic_group_24px" d="M17,12a3,3,0,1,0-3-3A2.987,2.987,0,0,0,17,12Zm-3,1c-.29,0-.62.02-.97.05A4.22,4.22,0,0,1,15,16.5V19h6V16.5C21,14.17,16.33,13,14,13Z" transform="translate(696.97 3411)" fill="#fff"/>
          </g>
        </svg>
      </router-link>
      <router-link :to="{name : 'HomeCustomer'}" :class="[isActiveHome ? 'active' : '']">
        <svg xmlns="http://www.w3.org/2000/svg" width="22.045" height="18.738" viewBox="0 0 22.045 18.738">
          <path id="ic_home_24px"
                d="M10.818,21.738V15.125h4.409v6.614h5.511V12.92h3.307L13.023,3,2,12.92H5.307v8.818Z"
                transform="translate(-2 -3)" fill="#d9b8df"/>
        </svg>
      </router-link>
      <router-link :to="{name : 'LocationCustomer'}" class="location" :class="[isActiveLocation ? 'active' : '']">
        <svg xmlns="http://www.w3.org/2000/svg" width="12.5" height="17.857" viewBox="0 0 12.5 17.857">
          <path id="ic_place_24px"
                d="M11.25,2A6.245,6.245,0,0,0,5,8.25c0,4.688,6.25,11.607,6.25,11.607S17.5,12.937,17.5,8.25A6.245,6.245,0,0,0,11.25,2Zm0,8.482A2.232,2.232,0,1,1,13.482,8.25,2.233,2.233,0,0,1,11.25,10.482Z"
                transform="translate(-5 -2)" fill="#d9b8df"/>
        </svg>
      </router-link>
      <router-link :to="{name : 'ProfileCustomer'}" class="profile" :class="[isActiveProfile ? 'active' : '']">
        <svg id="ic_record_voice_over_24px" xmlns="http://www.w3.org/2000/svg" width="14.687" height="15.834"
             viewBox="0 0 14.687 15.834">
          <circle id="Ellipse_3" data-name="Ellipse 3" cx="3.672" cy="3.672" r="3.672" transform="translate(3.672)"
                  fill="#d9b8df"/>
          <path id="Path_3" data-name="Path 3"
                d="M8.344,12.408C5.893,12.408,1,13.638,1,16.08v1.836H15.687V16.08C15.687,13.638,10.794,12.408,8.344,12.408Z"
                transform="translate(-1 -2.082)" fill="#d9b8df"/>
        </svg>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
name: "CustomerButton",
  data(){
    return{
      isActiveHome : false,
      isActiveLocation : false,
      isActiveProfile : false,
      activeItem : this.$route.name,
      customerHomeTour : null
    }
  },
  mounted() {
    var data = JSON.parse(localStorage.getItem('userTour'));
    this.customerHomeTour = data.customerHome;

    if (this.activeItem === 'HomeCustomer'){
      this.isActiveHome = true
    }else if(this.activeItem === 'LocationCustomer'){
      this.isActiveLocation = true
    }else if (this.activeItem === 'ProfileCustomer'){
      this.isActiveProfile = true
    }
  },
  methods :{
    changeTourStatus(){
      this.customerHomeTour = true;
      var data = JSON.parse(localStorage.getItem('userTour'));
      data.customerHome = true;
      localStorage.setItem('userTour' , JSON.stringify(data))
    }
  }
}
</script>

<style scoped>
.navigation-bottom >>> a.active.location::after{
  right: 4px;
}
.navigation-bottom >>> a.active.profile::after{
  right: 5px;
}
</style>