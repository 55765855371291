<template>
  <div>
    <template v-if="estates.length > 0">
      <router-link
          :to="{name : 'EstateCustomer' , params :{ id : estate.id}}"
          class="product z-0"
          v-for="(estate , i) in estates"
          :key="i"
      >
        <div class="image-section">
          <img class="image-product" :src="baseURL + estate.thumbnail.link" alt="">
          <div class="top">
            <span>{{ estate.advertisement_type }}</span>
            <img @click="handelOpenRealestate(estate.realestate.id)" :src="baseURL + [estate.realestate !== null ? estate.realestate.logo : ' ']  " :alt="[estate.realestate !== null ? estate.realestate.name : ''] ">
          </div>
          <div class="bottom ">
            <a @click="handleVideo(estate.video_link)" class="relative">مشاهده ویدئو
              <svg xmlns="http://www.w3.org/2000/svg" width="13.634" height="13.634" viewBox="0 0 13.634 13.634">
                <g id="Group_12813" data-name="Group 12813" transform="translate(-62.183 -563.183)">
                  <g id="instagram" transform="translate(62.183 563.183)">
                    <path id="Path_17917" data-name="Path 17917"
                          d="M9.942,0H3.69A3.7,3.7,0,0,0,0,3.691V9.944A3.7,3.7,0,0,0,3.69,13.634H9.943a3.7,3.7,0,0,0,3.691-3.691V3.691A3.7,3.7,0,0,0,9.942,0Zm2.891,9.944a2.9,2.9,0,0,1-2.891,2.891H3.69A2.9,2.9,0,0,1,.8,9.944V3.691A2.9,2.9,0,0,1,3.69.8H9.943a2.9,2.9,0,0,1,2.891,2.891Zm0,0"
                          transform="translate(0.001 0)" fill="#ffffffb5"/>
                    <path id="Path_17918" data-name="Path 17918"
                          d="M119.732,116a3.728,3.728,0,1,0,3.728,3.728A3.732,3.732,0,0,0,119.732,116Zm0,6.657a2.929,2.929,0,1,1,2.929-2.929A2.932,2.932,0,0,1,119.732,122.661Zm0,0"
                          transform="translate(-112.915 -112.915)" fill="#ffffffb5"/>
                    <path id="Path_17919" data-name="Path 17919"
                          d="M359.078,66.285a1.1,1.1,0,1,0,1.1,1.1A1.1,1.1,0,0,0,359.078,66.285Zm0,1.4a.3.3,0,1,1,.3-.3A.3.3,0,0,1,359.078,67.689Zm0,0"
                          transform="translate(-348.444 -64.52)" fill="#ffffffb5"/>
                  </g>
                </g>
              </svg>
            </a>
          </div>
        </div>
        <div class="detail-section">
          <div class="top">
            <p>{{ estate.title }}</p>
            <p v-if="estate.advertisement_type_en === 'rent' || estate.advertisement_type_en === 'mortgageRent'" class="text-white text-left">{{( '' +estate.options.downPayment).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}<span>تومان</span></p>
 <p v-else class="text-white text-left ">{{( '' +estate.options.price).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}  <span>تومان</span> </p>

            <a  @click="handleTell(estate.realestate.telephone)" class="bongah-filter call-estate z-50">
              <svg xmlns="http://www.w3.org/2000/svg" width="20.879" height="20.877" viewBox="0 0 20.879 20.877">
                <path id="Icon_ionic-ios-call" data-name="Icon ionic-ios-call" d="M24.766,20.85A17.537,17.537,0,0,0,21.1,18.4c-1.1-.527-1.5-.516-2.277.043-.647.467-1.065.9-1.81.739a10.8,10.8,0,0,1-3.636-2.69,10.72,10.72,0,0,1-2.69-3.636c-.158-.75.277-1.163.739-1.81.56-.777.576-1.179.043-2.277A17.19,17.19,0,0,0,9.02,5.1c-.8-.8-.978-.625-1.419-.467a8.073,8.073,0,0,0-1.3.69,3.919,3.919,0,0,0-1.56,1.647c-.31.669-.669,1.913,1.158,5.164a28.812,28.812,0,0,0,5.066,6.756h0l.005.005.005.005h0a28.925,28.925,0,0,0,6.756,5.066c3.25,1.826,4.495,1.468,5.164,1.158a3.852,3.852,0,0,0,1.647-1.56,8.073,8.073,0,0,0,.69-1.3C25.391,21.828,25.57,21.649,24.766,20.85Z" transform="translate(-4.49 -4.503)" fill="#fff"/>
              </svg>
            </a>
          </div>
          <div class="bottom">
            <div>
              <svg id="Group_12590" data-name="Group 12590" xmlns="http://www.w3.org/2000/svg" width="17.521"
                   height="11.136" viewBox="0 0 17.521 11.136">
                <g id="bed">
                  <path id="Path_9" data-name="Path 9"
                        d="M57.482,91.355A1.66,1.66,0,0,0,55.824,89.7H52.1a1.658,1.658,0,0,0-1.385.747,1.658,1.658,0,0,0-1.385-.747H45.609a1.66,1.66,0,0,0-1.658,1.658v.076h-.415V89.814a1.822,1.822,0,0,1,1.82-1.82H56.077a1.822,1.822,0,0,1,1.82,1.82v1.617h-.415v-.076Z"
                        transform="translate(-41.955 -87.994)" fill="#ff7400"/>
                  <path id="Path_10" data-name="Path 10"
                        d="M.7,204.076H16.824a.7.7,0,0,1,.7.7v4.046a.7.7,0,0,1-.7.7h-.29l.343.968a.387.387,0,1,1-.73.259l-.434-1.226H1.809l-.434,1.226a.387.387,0,0,1-.73-.259l.343-.968H.7a.7.7,0,0,1-.7-.7v-4.046A.7.7,0,0,1,.7,204.076Z"
                        transform="translate(0 -199.864)" fill="#ff7400"/>
                  <path id="Path_11" data-name="Path 11"
                        d="M259.87,156.262a.885.885,0,0,1,.884.884v.076h-5.491v-.076a.885.885,0,0,1,.884-.884Z"
                        transform="translate(-252.494 -153.785)" fill="#ff7400"/>
                  <path id="Path_12" data-name="Path 12"
                        d="M80.932,156.262a.885.885,0,0,1,.884.884v.076H76.325v-.076a.885.885,0,0,1,.884-.884Z"
                        transform="translate(-67.064 -153.785)" fill="#ff7400"/>
                </g>
              </svg>
              <span>{{ estate.options.number_of_bedrooms }} خوابه</span>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="13.776" height="12.398" viewBox="0 0 13.776 12.398">
                <path id="ic_business_24px"
                      d="M8.888,5.755V3h6.888V15.4H2V5.755ZM13.02,14.02H14.4V12.643H13.02Zm0-2.755H14.4V9.888H13.02Zm0-2.755H14.4V7.133H13.02Zm0-2.755H14.4V4.378H13.02ZM10.265,14.02h1.378V12.643H10.265Zm0-2.755h1.378V9.888H10.265Zm0-2.755h1.378V7.133H10.265Zm0-2.755h1.378V4.378H10.265ZM3.378,14.02h5.51V12.643H7.51V11.265H8.888V9.888H7.51V8.51H8.888V7.133H3.378ZM4.755,8.51H6.133V9.888H4.755Zm0,2.755H6.133v1.378H4.755Z"
                      transform="translate(-2 -3)" fill="#ff7400"/>
              </svg>
              <span>  {{ estate.options.age }} ساله</span>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="12.398" height="12.398" viewBox="0 0 12.398 12.398">
                <path id="ic_format_shapes_24px"
                      d="M13.4,4.381V1H10.017V2.127H4.381V1H1V4.381H2.127v5.635H1V13.4H4.381V12.271h5.635V13.4H13.4V10.017H12.271V4.381ZM2.127,2.127H3.254V3.254H2.127ZM3.254,12.271H2.127V11.144H3.254Zm6.763-1.127H4.381V10.017H3.254V4.381H4.381V3.254h5.635V4.381h1.127v5.635H10.017Zm2.254,1.127H11.144V11.144h1.127ZM11.144,3.254V2.127h1.127V3.254Z"
                      transform="translate(-1 -1)" fill="#ff7400"/>
              </svg>
              <span>{{ estate.options.area }}متر</span>
            </div>
          </div>
        </div>
      </router-link>
    </template>
    <div class="flex justify-center items-center h-96" v-else>
      <h3 class="text-white ">با این فیلتر ملکی وجود ندارد</h3>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Estates",
  data(){
    return{
      baseURL : this.$store.state.general.baseURL,
      url : this.$store.state.general.url,
      estatesWithFilter : null
    }
  },
  computed :{
    ...mapGetters({
      estates : 'filters/estates'
    })

  },
  methods :{
    handleVideo(data){
      window.open(data);
    },
    handelOpenRealestate(id){
      window.open(`${this.url}/customer/page/${id}` , "_self")
    },
    handleTell(data){
      window.open("tel:"+data, "_blank");
    }
  },
  async mounted() {
    await this.$store.commit('filters/CLEAR_ESTATES')
    await this.$store.dispatch('filters/getDataWithFilter')
  },
  watch :{

  }
}
</script>

<style scoped>

</style>